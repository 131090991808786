/** here keep it for Vue 3 **/
// import {ref, watch} from 'vue';
import {ref} from '@vue/composition-api';
import ValidationRules from './rules/ValidationRules';
import IdGenerator from "@/utils/yo-validator/IdGenerator";
import FormManager, {MODES} from "@/utils/yo-validator/forms/FormManager";
import FieldManager from "@/utils/yo-validator/fields/FieldManager";

const RULE_NAME_CONFIRMATION = 'passwordConfirm';

class YoValidator {
  constructor() {
  }

  static getInstance() {
    if (!this.instance) {
      this.instance = new YoValidator();
    }
    return this.instance;
  }

  setSelectedLang(lang) {
    ValidationRules.setSelectedLanguage(lang);
  }

  setValidationMessages(messages, lang) {
    ValidationRules.setValidationMessages(messages, lang);
  }

  /**
   * register a component like InputField, fieldName are rules (required|length:8) required, type is required for email
   * @param fieldName
   * @param rules
   * @param type
   * @returns {{formId: *, validateField: validateField, errorMessages: (Array|Ref<UnwrapRef<Array>>|Ref<any | undefined>),
   * setFieldData: setFieldData, handleChange: handleChange, handleInput: handleInput, fieldId: *} || boolean}
   */
  register({fieldName, rules, type}) {
    if (!fieldName || rules === null || rules === undefined) {
      console.error('Please pass in fieldName and rules');
      return false;
    }
    // generate unique id and it's own error message array
    const fieldId = IdGenerator.getId();
    let errorMessages = ref([]);
    // define callback for pub-sub
    const callback = ({id, messages}) => {
      if (fieldId === id) {
        errorMessages.value = Object.assign([], messages);
      }
    };
    const {formId, field} = FormManager.setFieldRules({fieldId, fieldName, rules, type, callback});
    /** define handle input for regular inputs **/
    const handleInput = this.getInputHandler({formId, fieldId, fieldName, rules});
    /** define handle change for change event like files **/
    const handleChange = (event) => {
      if (event.target && event.target instanceof HTMLElement) {
        FormManager.setFormDataByFormId({formId, fieldId, fieldName, data: event.target.files});
      }
    }
    /** directly set form data to the corresponding form **/
    const setFieldData = (data) => {
      FormManager.setFormDataByFormId({formId, fieldId, fieldName, data});
    }
    /** you can also get the function to validate single field if you already set field data on input change **/
    const validateField = () => {
      FormManager.validateFieldById(formId, fieldId);
    }
    /** you can also get the function to validate single field by passing data in **/
    const validateFieldByData = (data) => {
      FormManager.validateFieldByData(formId, fieldId, data);
    }
    // add it to the corresponding form fields
    return {
      formId,
      mode: FormManager.getFormModeByFormId(formId),
      fieldId,
      isPristine: field.isPristine,
      isDirty: field.isDirty,
      errorMessages,
      handleInput,
      handleChange,
      setFieldData,
      validateFieldByData,
      validateField
    };
  }

  /**
   * register a form
   * @param name
   * @param onFormDataUpdate
   * @param mode
   * @returns {{formId: *, handleSubmit: handleSubmit, validateFormFields: (function(): *)} || boolean}
   */
  registerForm({mode, name, onFormDataUpdate}) {
    const keyList = Object.keys(MODES);
    if (typeof mode !== 'number' || mode < MODES[keyList[0]] || mode > MODES[keyList[keyList.length - 1]]) {
      console.error('Please pass in valid mode');
      return false;
    }
    // get form according to mode
    const form = FormManager.addFormByMode({mode, name, onFormDataUpdate});
    // define handle submit validation method
    const handleSubmit = (callback) => {
      const {isFormValid, formData} = FormManager.validateFormFields(form.id);
      // submit with the callback
      if (isFormValid) {
        callback(formData);
      }
    }
    // define just a validate fields function for flexibility
    const validateFormFields = () => {
      return FormManager.validateFormFields(form.id);
    }
    return {
      formId: form.id,
      isPristine: form.isPristine,
      isDirty: form.isDirty,
      isInvalid: form.isInvalid,
      handleSubmit,
      validateFormFields
    };
  }

  registerField({fieldName, rules, type, mode}) {
    if (!fieldName || rules === null || rules === undefined) {
      console.error('Please pass in fieldName and rules');
      return false;
    }
    // generate unique id and it's own error message array
    const fieldId = IdGenerator.getId();
    let errorMessages = ref([]);
    // define callback for pub-sub
    const callback = ({id, messages}) => {
      if (fieldId === id) {
        errorMessages.value = Object.assign([], messages);
      }
    };
    const {formId, field} = FieldManager.setFieldRules({fieldId, fieldName, rules, type, mode, callback});
    /** define handle input for regular inputs **/
    const handleInput = (event) => {
      if (event.target && event.target instanceof HTMLElement) {
        FieldManager.setFieldDataById({fieldId, fieldName, data: event.target.value, target: event.target});
      }
    };
    /** define handle change for change event like files **/
    const handleChange = (event) => {
      if (event.target && event.target instanceof HTMLElement) {
        FieldManager.setFieldDataById({fieldId, fieldName, data: event.target.files});
      }
    }
    /** directly set form data to the corresponding form **/
    const setFieldData = (data) => {
      FieldManager.setFieldDataById({fieldId, data});
    }
    /** you can also get the function to validate single field if you already set field data on input change **/
    const validateField = () => {
      FieldManager.validateFieldById(fieldId);
    }
    /** you can also get the function to validate single field by passing data in **/
    const validateFieldByData = (data) => {
      FormManager.validateFieldByData(formId, fieldId, data);
    }
    // add it to the corresponding form fields
    return {
      formId,
      fieldId,
      isPristine: field.isPristine,
      isDirty: field.isDirty,
      errorMessages,
      handleInput,
      handleChange,
      setFieldData,
      validateFieldByData,
      validateField
    };
  }

  getInputHandler({formId, fieldId, fieldName, rules}) {
    if (rules.includes(RULE_NAME_CONFIRMATION)) {
      return (event) => {
        if (event.target && event.target instanceof HTMLElement) {
          /** here special case for password, 1 field 2 values, both need to be saved **/
          const passwordFieldName = event.target.name;
          if (!passwordFieldName) {
            console.error(`Please set different field names to the name property of 2 inputs for "passwordConfirm"`);
            return;
          }
          FormManager.setFormDataByFormId({
            formId,
            fieldId,
            fieldName: passwordFieldName,
            data: event.target.value,
            target: event.target
          });
        }
      }
    }
    return (event) => {
      if (event.target && event.target instanceof HTMLElement) {
        FormManager.setFormDataByFormId({formId, fieldId, fieldName, data: event.target.value, target: event.target});
      }
    };
  }
}

export default YoValidator.getInstance();