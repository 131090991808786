export default {
  names: {
    username: 'username',
    email: 'e-mailadres',
    numberField: 'nummmer',
    profile: 'profiel foto',
    organizationName: 'naam organisatie',
    location: 'locatie',
    contactPerson: 'contactpersoon',
    termsPrivacy: '',
    phoneNumber: 'telefoonnumer'
  },
  messages: {
    required: {
      default: 'Je moet een {fieldName} invoeren.',
      organizationName: 'Je moet een naam invoeren.',
      firstName: 'Je moet een naam invoeren.',
      location: 'Je moet een stad invoeren.',
      contactPerson: 'Je moet een naam invoeren.',
      termsPrivacy: 'Geef aan dat je akkoord bent met de algemene voorwaarden en privacybeleid.',
      phoneNumber: 'Je moet een telefoonnumer invoeren.',
      email: 'Je moet een e-mailadres invoeren.',
      password: 'Je moet een password invoeren.',
      confirmation: 'Je moet een password invoeren.',
    },
    alpha: {
      default: 'Uw {fieldName} mag alleen alfabetten bevatten'
    },
    alphaNumeric: {
      default: 'Uw {fieldName} mag alleen letters en cijfers bevatten'
    },
    numeric: {
      default: 'Uw {fieldName} mag alleen cijfers bevatten',
      phoneNumber: 'Gebruik cijfers.'
    },
    email: {
      default: 'Dit e-mailadres is onjuist.'
    },
    ext: {
      default: 'De extensie van uw {fieldName} mag alleen {ext} zijn',
    },
    max: {
      default: 'Uw {fieldName} mag niet groter zijn dan {max}'
    },
    min: {
      default: 'Uw {fieldName} mag niet kleiner zijn dan {min}'
    },
    maxLength: {
      default: 'Uw {fieldName} mag niet langer zijn dan {maxLength} tekens'
    },
    minLength: {
      default: 'Uw {fieldName} mag niet korter zijn dan {minLength} tekens'
    },
    maxSize: {
      default: 'Uw {fieldName} mag niet groter zijn dan {maxSize}MB'
    },
    minSize: {
      default: 'Uw {fieldName} mag niet kleiner zijn dan {minSize}MB'
    },
    passwordConfirm: {
      default: 'Deze wachtwoorden komen niet overeen.'
    },
    telephoneNumber: {
      default: 'Gebruik cijfers.'
    },
    passwordStrength: {
      default: 'Wachtwoorden moeten tussen de 6 en 20 tekens lang zijn en minstens één hoofdletter, één kleine letter en één cijfer bevatten'
    }
  }
}
